
import React from "react";
import { Link } from "react-router-dom";

import './BannerApoioMorelli.css';

function BannerApoioMorelli() {


    return (
        <div>
<br />
<div className="divApoioMorelli">
    <span className="spanApoioMorelli">APOIO:</span><br />
    <Link to="https://www.morelli.com.br" target="_blank" title="Morelli Ortodontia">
    <img src="/imagens/Logotipo-bottom160.png" alt="Morelli" height="32" width="160" /></Link>
</div>
<br />
<br />
</div>
    );
}

export default BannerApoioMorelli;
