import React from "react";
import { logout } from "../firebase";

import './BotaoDesconectar.css';

function BotaoDesconectar(props){ 

    const classeAdd = "btnFacaDoacao btnDesconectarLogin  " +( props.className ? props.className : "" );

    return (
        <button className={classeAdd} onClick={logout}>Desconectar</button>
    );
}

export default BotaoDesconectar;