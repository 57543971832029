import React, { useEffect, useState } from "react";
import { Route, useNavigate } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, db, logout } from "../firebase";

const ProtectedRoute = (props) => {

    const navigate = useNavigate();
    const voltarPara = props.voltar ? "?voltar=" + props.voltar : "";

    const [isAuthenticated, setisAuthenticated] = useState(false);

    const checkUserToken = () => {
        const userToken = localStorage.getItem('user-token');
        if (!userToken || userToken === 'undefined') {
          setisAuthenticated(false);
          return navigate('/login'+voltarPara);
        }
        setisAuthenticated(true);
    }

    useEffect(() => {
        checkUserToken();
    }, [checkUserToken, isAuthenticated]);

    return (
        <React.Fragment>
            {
                isAuthenticated ? props.children : null
            }
        </React.Fragment>
    );
}

export default ProtectedRoute;