
import BotaoDoacao from "padrao/BotaoDoacao";
import React from "react";
import { Link } from "react-router-dom";

import './ConectarRegistrar.css';

function ConectarRegistrar(props) {

    const isMostrarConectar = !!props.isMostrarConectar;
    
    return (
        <div className="container divUsuarioLogin divBotoesLogin">
        
            <BotaoDoacao />
            { isMostrarConectar ? 
                <Link to="/login">
                    <button className="btnAcesse" data-toggle="modal">ACESSE</button>
                </Link>
              : 
              <label/> 
            }
        </div>
    );

}

export default ConectarRegistrar;
