

import { initializeApp, firebase } from "firebase/app";
import {
  GoogleAuthProvider,
  getAuth,
  signInWithPopup,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  signOut,
} from "firebase/auth";

import {
  getFirestore,
  query,
  getDocs,
  collection,
  where,
  addDoc,
} from "firebase/firestore";

import { getAnalytics } from 'firebase/analytics';

import { getFunctions, httpsCallable, connectFunctionsEmulator } from "firebase/functions";

// import { requestIp } from 'request-ip';

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyBlcp6sdAFgcaGgv7ByZZ45hYKN4R-hVcU",
    authDomain: "ortofechado.firebaseapp.com",
    databaseURL: "https://ortofechado-default-rtdb.firebaseio.com",
    projectId: "ortofechado",
    storageBucket: "ortofechado.appspot.com",
    messagingSenderId: "783328387586",
    appId: "1:783328387586:web:0562eaee0f4c9fb051bd1b",
    measurementId: "G-50FYKKL44S"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const functions = getFunctions(app);

const analytics = getAnalytics(app);

if (process.env.NODE_ENV === 'development') {
  connectFunctionsEmulator(functions, 'localhost', '5000');
} 


const googleProvider = new GoogleAuthProvider();


async function getUserData(uid) {
  const q = query(collection(db, "users"), where("uid", "==", uid));
  const docs = await getDocs(q);
  if (!docs.empty) {
    return docs[0].data();
  }
  return null;
}
const signInWithGoogle = async () => {
  try {
    const res = await signInWithPopup(auth, googleProvider);
    const user = res.user;
    const q = query(collection(db, "users"), where("uid", "==", user.uid));
    const docs = await getDocs(q);
    if (docs.docs.length === 0) {
      await addDoc(collection(db, "users"), {
        uid: user.uid,
        name: user.displayName,
        authProvider: "google",
        email: user.email,
        dataRegistro: new Date(),
      });
    }
    localStorage.setItem("user-token", user.accessToken);
  } catch (err) {
    console.error(err);
    //alert(err.message);
    alert('Ocorreu um erro ao efetuar login. Por favor, tente novamente.');
  }
};

const logInWithEmailAndPassword = async (email, password) => {
  try {
    let usr = await signInWithEmailAndPassword(auth, email, password);
    
    localStorage.setItem("user-token", usr.user.accessToken);
  } catch (err) {
    console.error(err);
    //alert(err.message);
    alert('Não foi possível efetuar login. Por favor, tente novamente.');
  }
};

const registerWithEmailAndPassword = async (name, email, password) => {
  try {
    const res = await createUserWithEmailAndPassword(auth, email, password);
    const user = res.user;

    await addDoc(collection(db, "users"), {
      uid: user.uid,
      name: name,
      authProvider: "local",
      email: email,
      dataRegistro: new Date(),
    });
    localStorage.setItem("user-token", user.accessToken);
    
    //auth.currentUser.updateProfile({displayName: name});

  } catch (err) {
    console.error(err);
    //alert(err.message);
    alert('Não foi possivel efetuar seu registro. Por favor verifique as informações digitadas.');
  }
};

const sendPasswordReset = async (email) => {
  try {
    await sendPasswordResetEmail(auth, email);
    alert("O email de redefinição de senha foi enviado !");
  } catch (err) {
    console.error(err);
    //alert(err.message);
    alert('Ocorreu um erro e não foi possível enviar o email de redefinição de senha.');
  }
};

const logout = () => {
  signOut(auth);
  localStorage.removeItem("user-token");
  window.location.href = '/';
};

export {
  app,
  auth,
  db,
  signInWithGoogle,
  logInWithEmailAndPassword,
  registerWithEmailAndPassword,
  sendPasswordReset,
  logout,
  getUserData,
  functions,
};