
import React  from "react";

import './PaginaFechada.css';

function PaginaFechada() {


    return (
        <h1>Pagina fechada</h1>
    );
}

export default PaginaFechada;