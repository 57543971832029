import { getApp } from "firebase/app";
import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';

import { getFunctions, httpsCallable, connectFunctionsEmulator } from "firebase/functions";

import Vimeo from '@u-wave/react-vimeo';
// import {functions} from "./firebase";

// import { getFunctions, httpsCallable, connectFunctionsEmulator } from "firebase/functions";

import './VerVideoPalestra.css';

const functions = getFunctions(getApp());
const getListaVideoPalestras = httpsCallable(functions, 'getListaVideosPalestras');

function VerVideoPalestra(props) {
    //const token = 'seu-token-de-acesso-do-vimeo';
    //const videoId = 'id-do-seu-video';
    //const url = `https://player.vimeo.com/video/${videoId}?badge=0&autopause=0&player_id=0&app_id=58479&api=1&access_token=${token}`;
    const idFilme = props.idEpisodio;

    console.info("PROPS",idFilme, props);
    const [listaVideos, setListaVideos] = useState([]);

    var [filme, setFilme] = useState({});

    useEffect(() => {

        getListaVideoPalestras() 
        .then((lista)=>{
          setListaVideos(lista.data);
          console.info("FILMES: ",lista.data);
          if (lista && lista.data) {
            lista.data.forEach(film => {
                console.info("loop:",film);
                if (idFilme == film.Episodio) {
                    console.info("filme:",film);
                    setFilme(film);
                }
                });
             }
        });
      
      }, []);

      /* embed-responsive-16by9 
            <div className="divRodapeFilme">
                <img className="imgLogoVideo" alt="logo" src="/imagens/LogoMorelli-Player-325x60.png" />
            </div>
      */
    return (
      <div>
        <div id="divFilmeFull" className="telaCheia">
            <div id="divTopoFilme" className="divTopoFilme">
                <div id="h1Titulo">{filme.Titulo}</div>
                <Link to="/palestras">
                <button id="imgFecharVideo"><i className="fa fa-times"></i></button></Link>
            </div>
            <div id="divFilmeAtual" className="embed-responsive embed-responsive-16by9">
                { filme.Id_Video ?
                    <Vimeo id="divPlayVimeo" video={"https://vimeo.com/"+filme.Id_Video} autoplay={true} controls={true} playsInline={false} />
                : 
                    <div/>
                }
            </div>
        </div>
      </div>
    );
  }

  export default VerVideoPalestra;
