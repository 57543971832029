
import React from "react";
import { Link } from "react-router-dom";

function BotaoDoacao(){ 

    return (
        <Link to="http://www.amassorocaba.org.br/como-ajudar" target="_blank" className="linkAmigosBem"><button className="btnFacaDoacao" >FAÇA SUA DOAÇÃO</button></Link>
    );
}

export default BotaoDoacao;