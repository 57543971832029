
import React, { useEffect, useState } from "react";

import './ListaPalestrantes.css';

function ListaPalestrantes() {

    const [palestrantes, setPalestrantes] = useState([]);

    useEffect(() => {
        async function fetchPalestrantes() {
          const response = await fetch('/json/Lista_Palestrantes.json');
          const data = await response.json();
          setPalestrantes(data);
        }
        fetchPalestrantes();
      }, []);

      palestrantes.sort((a,b) => a.Ordenacao - b.Ordenacao );

    return (
        <div className="container divListaPalestrantes">
            { palestrantes.map((palestrante) => (
            <div className="col col-sm iconePalestrante" key={palestrante.id_Palestrante}>
                <div className="divPalestrante">
                    <picture className="imgPalestrante">
                        <source type="image/webp" className="imgPalestrante" width="264" height="264" srcSet={palestrante.Icone_Palestrante.replace('.png', '.webp')} />
                        <source type="image/png" className="imgPalestrante"  width="264" height="264" srcSet={palestrante.Icone_Palestrante} />
                        <img src={palestrante.Icone_Palestrante} width="264" height="264" alt="" />
                    </picture>
                    <div className="spanPalestrante">{palestrante.Nome_Palestrante}</div>
                </div>
            </div>
        ))}
        </div>
    );

}

export default ListaPalestrantes;