import React, { useEffect, useState } from "react";
//import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import "./PaginaVideos.css";
//import { auth, db, logout } from "./firebase";
//import { query, collection, getDocs, where } from "firebase/firestore";
import ListadeVideos from "./ListadeVideos";
import PaginaObrigadoRegistro from './PaginaObrigadoRegistro';
import CabecalhoLogotipo from "CabecalhoLogotipo";

function PaginaVideos(props) {

//  const [user, loading, error] = useAuthState(auth);
  const user = props.user;
  const navigate = useNavigate();
  const mostrarListaPalestras = !!props.mostrarListaPalestras;
  const cssLista = "paginavideos " + ( mostrarListaPalestras ? "centradoTopo" : "");

  useEffect(() => {
    //if (loading) return;
    //if (!user) return navigate("/");

    //fetchUserName();
  });

  return (
    <div className={cssLista}>
      { mostrarListaPalestras ? (
        <ListadeVideos />
        )
        :
        (
          <div>
            <CabecalhoLogotipo/>
            <PaginaObrigadoRegistro />
        </div>
        )
      }
    </div>
  );
}
export default PaginaVideos;