
import React from "react";
import { Link } from "react-router-dom";

import './CabecalhoLogotipo.css';

function CabecalhoLogotipo(props) {

    const somenteLogotipo = !!props.somenteLogotipo;
    const tamanhoLogotipo = "container-fluid divTituloImg " + (somenteLogotipo ? "max250px" : "");

    return (
        <div>
            <Link to={"/"}>
                <div className={tamanhoLogotipo}>
                    <img src="/imagens/2meeting2023.png" alt="Meeting Ortodontia Fechado" className="img-fluid img1MeetingOrtoFechado"/>
                </div>
            </Link>
            { !somenteLogotipo ?
                <div className="container-fluid divEstreia">
                    <div className="container-fluid tituloTemporadas">
                        <div className="fonteAmarelo tituloSerieIncrivel">
                            Os Grandes Nomes da Ortodontia<br />
                            com <b>palestras online imperdíveis.</b>
                        </div>
                    </div>
                    <div className="container-fluid tituloTemporadas tituloAcessoConteudos">
                        <div className="row">
                            <div className="col-2">
                                <img src="/imagens/Icone_doação.png" className="imgIconeDoacao" width="96" height="96"  />
                            </div>
                            <div className="col-10">
                            O acesso ao conteúdo é liberado para qualquer profissional que queira assistir aos vídeos, mas pedimos gentilmente a
                                <b className="bAmarelo"> doação mínima de R$ 50</b>, que serão destinados à <b className="bAmarelo">
                                    <Link to="http://www.amassorocaba.org.br/como-ajudar" target="_blank" className="linkAmigosBem">ONG AMAS (Associação Amigos dos Autistas de Sorocaba)</Link></b>. Obrigado.
                            </div>
                        </div>
                    </div>
                </div>
            : <label/> 
            }
        </div>
    );

}

export default CabecalhoLogotipo;