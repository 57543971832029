
import React from "react";

import './PaginaEmConstrucao.css';

function PaginaEmConstrucao() {

    return (
        <div className="textoPromocao">
            <div className="pTextoPromocao">
                <strong>ESTE SITE ESTÁ EM CONSTRUÇÃO</strong><br />
                <br />
                Fique ligado nas nossas redes sociais, em <br />
                breve teremos mais novidades para você!
            </div>
        </div>
    );

}

export default PaginaEmConstrucao;