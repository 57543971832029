
import React from "react";
//import { auth, db, logout } from "../firebase";

import './Rodape.css';

function loadShareThis() {
    try{
        if(window.__sharethis__) {
            window.__sharethis__.initialize();
        }
    }
    catch(erro) {
        console.error(erro);
    }
}

function Rodape(){

    loadShareThis();
    
    return (
        <div className="container-fluid divRodapeOF">
        <span className="snapConvideAmigo">Convide um amigo ortodontista para assistir também:</span>
        <div className="divShareThis sharethis-inline-share-buttons btnsShare" data-title="Não fique de fora dessa nova série da ortodontia !" data-description="Não fique de fora dessa nova série da ortodontia !"></div>
        <div className="sharethis-inline-follow-buttons btnFollow btnFollow1"></div>
    </div>

    );
}

export default Rodape;
