
import React from "react";

import './Apresentacao.css';
import ListaPalestrantes from './ListaPalestrantes';
import CabecalhoLogotipo from './CabecalhoLogotipo';
import ConectarRegistrar
 from "./ConectarRegistrar";
import BannerApoioMorelli from "./BannerApoioMorelli";
function Apresentacao(props) {

    return (
        <div>
            <CabecalhoLogotipo/>
            <ConectarRegistrar isMostrarConectar={props.isMostrarConectar} />
            <ListaPalestrantes />
            <div className="divNaoFiqueDeFora">Não perca nenhuma palestra desse evento!</div>
            <ConectarRegistrar  isMostrarConectar={props.isMostrarConectar}/>
            <BannerApoioMorelli/>
        </div>
    );

}

export default Apresentacao;