import React, { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { auth, logInWithEmailAndPassword, signInWithGoogle } from "./firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import "./Login.css";
import CabecalhoLogotipo from "./CabecalhoLogotipo";


function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [user, loading, error] = useAuthState(auth);
  const navigate = useNavigate();

  const location = useLocation()
  const params = new URLSearchParams(location.search)

  const parVoltar = params.get("voltar");

  useEffect(() => {
    if (loading) {
      // maybe trigger a loading screen
      return;
    }
    if (user) {
      if(parVoltar && parVoltar.startsWith('/')) {
        navigate(parVoltar);
      } else {
        navigate("/palestras");
      }
    }
  }, [user, loading]);

  return ( 
  <div>
        <CabecalhoLogotipo somenteLogotipo="true" />

    <div className="login">
      <div className="login__container modalAcessar">
      <div className="headerLogin modalAcessar">
          ACESSAR
        </div>
        <input
          type="text"
          className="login__textBox"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="E-mail"
        />
        <input
          type="password"
          className="login__textBox"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Senha"
        />
        <button
          className="login__btn"
          onClick={() => logInWithEmailAndPassword(email, password)}
        >
          ACESSAR
        </button>
        <button className="login__btn login__google" onClick={signInWithGoogle}>
          Acessar com o Google
        </button>
        <div>
          <Link to="/reset">Esqueceu sua senha ?</Link>
        </div>
        <div>
          Não está registrado ainda ? <Link to="/register">REGISTRE-SE</Link> agora.
        </div>
      </div>
    </div>
    </div>
  );
}

export default Login;