import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes, Navigate, useParams } from "react-router-dom";
import "./App.css";
import "./App2.css";

import { auth, logout } from './firebase';
import { useAuthState } from "react-firebase-hooks/auth";

import Cabecalho from "./padrao/Cabecalho";
import moment from 'moment';
import PaginaEmConstrucao from "./PaginaEmConstrucao";
import PaginaFechada from "./PaginaFechada";
import Rodape from "./padrao/Rodape";
import VerVideoPalestra from "./VerVideoPalestra";
import Apresentacao from './Apresentacao';
import Login from "./Login";
import Register from "./Register";
import Reset from "./Reset";
import PaginaVideos from "./PaginaVideos";
import EmitirCertificado from "./EmitirCertificado";
import ProtectedRoute from "util/ProtectedRoute";
import PaginaObrigadoRegistro from "PaginaObrigadoRegistro";
import CabecalhoLogotipo from "CabecalhoLogotipo";

function App() {

  const [user, setUser] = useState(null);
  const [isAuthenticated, setisAuthenticated] = useState(false);
  const [user2, loading, error] = useAuthState(auth);

  const checkUserToken = () => {
    const userToken = localStorage.getItem('user-token');
    if (!userToken || userToken === 'undefined') {
      setisAuthenticated(false);
    } else {
      setisAuthenticated(true);
    }
}
//console.info(location);


  useEffect(() => {
    if(loading) return;
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setUser(user);
      console.info(user);
      if (user && user.accessToken) {
        localStorage.setItem('user-token', user.accessToken);
      } else {
        localStorage.removeItem('user-token');
      }
      setisAuthenticated(!!user);
    });
  });

  const dataAbertura = moment('2023-04-20');
  const dataFechamento = moment('2025-01-01');
  const dataInicioExibicao = moment('2023-04-20');

  const isBeforeAbertura = moment().isBefore(dataAbertura);
  const isAfterFechamento =moment().isAfter(dataFechamento);
  const isMostrarConectar = moment().isAfter(dataAbertura);
  const mostrarListaPalestras = moment().isAfter(dataInicioExibicao);

  const { id } = useParams();

  return (
    <div className="app">
      <Cabecalho/>
      {isBeforeAbertura ?
        <PaginaEmConstrucao />
        :
        isAfterFechamento ?
        <PaginaFechada/>
        :
        <Router>
          <Routes>
            <Route exact path="/" element={<Apresentacao  isMostrarConectar={isMostrarConectar}/>}/>
            <Route exact path="/login" element={<Login />} />
            <Route exact path="/register" element={<Register />} />
            <Route exact path="/reset" element={<Reset />} />
            <Route exact path="/emitircertificado" element={
              mostrarListaPalestras || true ? 
              <ProtectedRoute voltar={"/emitircertificado"}>
                <EmitirCertificado user={user}/>
              </ProtectedRoute> : 
              <div class="divObrigadoRegistro">
                <CabecalhoLogotipo/>
                <PaginaObrigadoRegistro/>
                </div>
              }/>
            <Route exact path="/palestras" element={
              <ProtectedRoute voltar={"/palestras"}>
                  <PaginaVideos mostrarListaPalestras={mostrarListaPalestras} user={user} />
               </ProtectedRoute>
              }
            />
            <Route exact path="/verpalestra/:id" element={<VerPalestra isMostrarConectar={isMostrarConectar} mostrarListaPalestras={mostrarListaPalestras} />}/>
            <Route path="*" element={<Navigate replace to={"/"} />}/>
          </Routes>
        </Router>
      }
      <Rodape/>
      </div>
  );
}

/*
                <ProtectedRoute voltar={'/verpalestra/{id}'}>
                  <VerVideoPalestra
                      idEpisodio={id}
                      isMostrarConectar={isMostrarConectar}
                      mostrarListaPalestras={mostrarListaPalestras}
                  />
               </ProtectedRoute>
*/

function VerPalestra(props) {
  const { id } = useParams();

  return (
    <ProtectedRoute voltar={`/verpalestra/${id}`}>
      <VerVideoPalestra
        idEpisodio={id}
        isMostrarConectar={props.isMostrarConectar}
        mostrarListaPalestras={props.mostrarListaPalestras}
      />
    </ProtectedRoute>
  );
}


export default App;