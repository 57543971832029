import { getApp } from "firebase/app";
import React, { useEffect, useState } from "react";
import moment from 'moment';

import {BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';
import {getFunctions, httpsCallable, connectFunctionsEmulator } from "firebase/functions";

import CabecalhoLogotipo from "CabecalhoLogotipo";
import BotaoDoacao from "padrao/BotaoDoacao";
import BotaoDesconectar from "padrao/BotaoDesconectar";
// import {functions} from "./firebase";

import './ListadeVideos.css';

const functions = getFunctions(getApp());
const getListaVideoPalestras = httpsCallable(functions, 'getListaVideosPalestras');
 
function ListadeVideos(props) {

    const [listaVideos, setListaVideos] = useState([]);
    const somenteEstreia = !!props.somenteEstreia;

    useEffect(() => {

        getListaVideoPalestras() 
        .then((lista)=>{
          setListaVideos(lista.data);
          //console.info(lista.data);
        });

      }, []);

      const divPalestras = "divInicioPalestras" + (somenteEstreia ? " fundoEstreia" : "");

    return (
        <div className={divPalestras}>
            <CabecalhoLogotipo/>
            <BotaoDoacao/>
            <MostrarListagemPalestras listaVideos={listaVideos} somenteEstreia={true}/>
            <MostrarListagemPalestras listaVideos={listaVideos} somenteEstreia={false}/>
            <BotaoDesconectar/>
            <br/>
        </div>
    );
}

function MostrarListagemPalestras(props) {

    const listaVideos = props.listaVideos;
    const somenteEstreia = !!props.somenteEstreia;
    var contaFilme = 1;

    return (
        <div className={ somenteEstreia ? "padraoLista listaPad0 fundoEstreia": "padraoLista listaPad0"}>
            <div className={somenteEstreia ? "container fundoEstreia" : "container"}>
                <div className="container listaPad10">
                    { listaVideos.map((filme) => {
                        const dataHoje = moment();
                        const estaNoPrazo = moment().isAfter(filme.Data_Inicio) && moment().isBefore(filme.Data_Termino);
                        const estreiaHoje = dataHoje.isSame( filme.Data_Inicio, 'day');
                        const classePrimeiro = (!somenteEstreia && estreiaHoje && contaFilme == 1 ? "divListaHidden ": "" ) +
                            ( moment().isAfter(filme.Data_Inicio) ? " linhaFilmeJaExibido ": " ") +
                            ( moment().isBefore(filme.Data_Inicio) && !estreiaHoje ? " linhaFilmeFuturo " : "" );
                        const filmeFuturo = !estreiaHoje && moment().isBefore(filme.Data_Inicio);
                        contaFilme++;

                        if (somenteEstreia && !estreiaHoje) {
                            return null;
                        }

                        return (
                        <div key={"filme_"+filme.Episodio}>
                        <div className={ "row linhaFilme " + classePrimeiro }>
                            <div className="col-md-5 listaPad0">
                                { estaNoPrazo && filme.Id_Video !== "999/999" ?
                                <Link to={ "/verpalestra/" + filme.Episodio }>
                                <div className="card iconeFilme divIconeFilme">
                                    <picture className="card-img img-fluid">
                                        <source type="image/webp" srcSet={ filme.URL_Icone.replace(".jpg", ".webp") } />
                                        <source type="image/jpeg" srcSet={ filme.URL_Icone } />
                                        <img className="card-img img-fluid" src={ filme.URL_Icone } alt="" />
                                    </picture>

                                    <div className="card-img-overlay text-white d-flex justify-content-center align-items-center">
                                        <div className="btn btnPlay"><i className="fa fa-play"></i></div>
                                    </div>
                                </div>
                                </Link>
                                    :
                                    <div className="card divIconeFilmeDisable">
                                    <picture className="card-img img-fluid divIconeFilmeDisable">
                                        <source type="image/webp" srcSet={ filme.URL_Icone.replace(".jpg", ".webp") } />
                                        <source type="image/jpeg" srcSet={ filme.URL_Icone } />
                                        <img className={ "card-img img-fluid divIconeFilmeDisable" + ( filmeFuturo? "divTransparente":"") } src={ filme.URL_Icone } alt="" />
                                    </picture>
                                </div> 
                                }
                            </div>
                            <div className="col-md-7">
                                <h3 className="margemEpisodio"></h3>
                                <h3 className="tituloEspisodio bAmarelo">{ filme.Palestrantes }</h3>
                                <h4 className="tituloPalestrante">{ filme.Titulo }</h4>
                                <p className="DescricaoFilme DescricaoFilme2">
                                    { filme.Descricao }
                                </p>
                                { (estaNoPrazo && filme.URL_PDF !== "" && filme.URL_PDF !== "pdfs/DocumentoVazio.pdf") ?
                                    <div className="btnDownloadPDF">
                                        <a href={filme.URL_PDF} target="_blank">Download do Documento</a>
                                    </div>
                                    :
                                    <div className="btnDownloadPDF semPointer" disabled={true}>
                                        Em Breve download do documento 
                                    </div>
                                }
                                
                            </div>
                        </div>
                        <hr className="HRSeparadorFilme"/>
                        </div>
                        )
                        })
                    }
                </div>
            </div>
        </div>
    );
}

/*
<div class="<%= SomenteEstreia ? "fundoEstreia" : "" %>" style="">
    <div class="<%= SomenteEstreia ? "container fundoEstreia" : "container" %>">
        <div class='container' style="padding-left:10px; padding-right:10px;">
            <% foreach (MorelliVideos.Classes.Filme filme in filmes)
                {
                    bool estaNoPrazo = ((DateTime.Now >= filme.Data_Inicio) && (DateTime.Now <= filme.Data_Termino || !foraDaEmpresa));
                    bool estreiaHoje = DateTime.Now.ToString("dd/MM/yyyy") == filme.Data_Inicio.ToString("dd/MM/yyyy");
                    string stylePrimeiro = !SomenteEstreia && estreiaHoje && contaFilme == 1 ? "display:none;" : "";
                    contaFilme++;
            %>

            <div class="row linhaFilme <%= SomenteEstreia ? "fundoEstreia" : "" %> <%= filme.Data_Inicio < DateTime.Now? "linhaFilmeJaExibido" : "" %> <%= filme.Data_Inicio > DateTime.Now && !estreiaHoje ? "linhaFilmeFuturo" : "" %>"
                style="<%= stylePrimeiro %>">
                <div class="col-md-5" style="padding: 0px;">
                    <% 
                        if (Esta_Conectado && estaNoPrazo && filme.Id_Video != "999/999")
                        {
                    %>
                    <div class="card iconeFilme divIconeFilme" onclick="verfilme('<%= filme.Id_Video %>', '<%= filme.Titulo.Replace("'", "`") %>',usuario);">

                        <picture class="card-img img-fluid">
                            <source type="image/webp" srcSet="<%= filme.URL_Icone.Replace(".jpg", ".webp") %>">
                            <source type="image/jpeg" srcSet="<%= filme.URL_Icone %>">
                            <img class="card-img img-fluid" src="<%= filme.URL_Icone %>" alt="" />
                        </picture>

                        <div class="card-img-overlay text-white d-flex justify-content-center align-items-center">
                            <div class="btn btnPlay"><i class="fa fa-play"></i></div>
                        </div>
                    </div>
                    <% }
                        else
                        {  %>
                    <div class="card divIconeFilmeDisable">

                        <!--<img class="card-img img-fluid divIconeFilme" src="<%= filme.URL_Icone %>" style="<%= !estreiaHoje && filme.Data_Inicio > DateTime.Now ? "opacity: 0.6" : "" %>" />-->

                        <picture class="card-img img-fluid divIconeFilmeDisable">
                            <source type="image/webp" srcSet="<%= filme.URL_Icone.Replace(".jpg", ".webp") %>">
                            <source type="image/jpeg" srcSet="<%= filme.URL_Icone %>">
                            <img class="card-img img-fluid divIconeFilmeDisable" src="<%= filme.URL_Icone %>" alt="" style="<%= !estreiaHoje && filme.Data_Inicio > DateTime.Now ? "opacity: 0.6; filter:brightness(100%);": "" %>" />
                        </picture>

                        <% if (!estreiaHoje)
                            { %>
                        <!--<div class="card-img-overlay text-white d-flex justify-content-center align-items-end overlay-dark">
                        <p><%= estaNoPrazo ? "" : ""  %></p>
                    </div>-->
                        <% } %>
                    </div>
                    <% } %>
                </div>
                <div class="col-md-7">

                    <h3 class="margemEpisodio">
                        <!--div class="btn btnEpisodio">Ep. <%= filme.Episodio %></!--div-->
                        <% if (!estaNoPrazo && !estreiaHoje)
                            { %>
                        <!--span class="DatasFilme">Estreia dia <%= filme.Data_Inicio.ToString("dd/MM/yyyy") %> às <%= filme.Data_Inicio.ToString("HH") %>h</span-->
                        <% }
                        else
                        {
                            if (estreiaHoje)
                            {

                        %>
                        <!--span class="tituloEstreiaHoje"><%= filme.Data_Inicio < DateTime.Now ? "ESTREOU" : "ESTREIA" %> HOJE às <%= filme.Data_Inicio.ToString("HH") %>h</!--span-->
                        <%
                            }
                            else
                            {
                        %>
                        <!--span class="DatasFilmePassado">Exibido em <%= filme.Data_Inicio.ToString("dd/MM/yyyy") %></!--span-->
                        <%
                                }
                            }
                        %>
                    </h3>
                    <h3 class="tituloEspisodio"><%= filme.Palestrantes %></h3>
                    <h4 class="tituloPalestrante"><%= filme.Titulo %><!--span class="tempoEpisodio"><%= filme.duracao %></!--span--></h4>
                    <p class="DescricaoFilme" style="min-height:32px;">
                        <%= filme.Descricao %>
                    </p>

                    <% 
                        string txtBtnDownload = "Em Breve ";
                        string txtDisabled = " disabled";
                        if (Esta_Conectado && estaNoPrazo && filme.URL_PDF != "" && filme.URL_PDF != "pdfs/DocumentoVazio.pdf")
                        {
                            txtBtnDownload = "";
                            txtDisabled = "";
                        }
                    %>
                    <div class="btnDownloadPDF <%= txtDisabled %>">
                        <a href="<%= filme.URL_PDF %>" target="_blank"><%= txtBtnDownload %> Download do PDF</a>
                    </div>
                    <%
                        
                    %>
                </div>
            </div>
            <hr class="HRSeparadorFilme" />
            <% } %>
        </div>
    </div>
</div>
        </div>
    );
*/


export default ListadeVideos;