
// import React, { useEffect, useState } from "react";

import BotaoDesconectar from 'padrao/BotaoDesconectar';
import BotaoDoacao from 'padrao/BotaoDoacao';
import './PaginaObrigadoRegistro.css';

function PaginaObrigadoRegistro() {


    return (
        <div className="divObrigadoRegistro">
            <h3 className="fonteAmarelo">Obrigado pelo seu registro!</h3>
            <h4>Em breve publicaremos as novidades.</h4>
            <br/>
            <br/>
            <BotaoDoacao/>
            <BotaoDesconectar/>
        </div>
    );
}

export default PaginaObrigadoRegistro;