
import React, { useEffect, useState } from "react";
//import { Link } from "react-router-dom";
import { auth, logout } from "../firebase";

//import { useAuthState } from "react-firebase-hooks/auth";
//import { useNavigate } from "react-router-dom";
//import { Link } from "react-router-dom";
//import { auth, sendPasswordReset } from "../firebase";

import './Cabecalho.css';

function Cabecalho(){

    const [user, setUser] = useState(null);
    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged((user) => {
          setUser(user);
        });
        return unsubscribe;
      }, []);

      const desconectar = function() {
        logout();
        window.location.href = "/";
      }

    return (
        <div className="navbar navbar-static-top divTopo1">
            <ul className="navbar-nav mr-auto" >
            </ul>
            <ul className="navbar-nav">
                 <li className="nav-item liNavItem">
                     <span>APOIO:</span> <a href="https://www.morelli.com.br" target="_blank" title="Morelli Ortodontia" rel="noreferrer"><img alt="logo" src="/imagens/Logotipo-topo38.png" height="32" width="147" /></a>
                 </li>
                 {user &&
                    <li className="nav-item divUsuarioLogado divMobileDesconectar">
                        <a className="nav-link mvBotaoLogout cursorPointer " id="aLinkDesconectar" title="Desconectar" onClick={desconectar}> DESCONECTAR </a>
                    </li>
                 }
                <li id="divUsuarioLogin" className="nav-item divUsuarioLogin liNavItemHidden">
                </li>
            </ul>
    </div>
    );
}

export default Cabecalho;
