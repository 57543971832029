import React, {useState,  useRef, useEffect} from "react";
import { getApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFunctions, httpsCallable, connectFunctionsEmulator } from "firebase/functions";
import { useAuthState } from "react-firebase-hooks/auth";
import { query, collection, getDocs, where } from "firebase/firestore";

import CabecalhoLogotipo from "CabecalhoLogotipo";
import { useNavigate } from "react-router-dom";
import { auth, db, app, getUserData, logout} from "./firebase";
import BotaoDesconectar from "padrao/BotaoDesconectar";

import "./EmitirCertificado.css";

const functions = getFunctions(getApp());
const gerarCertificado = httpsCallable(functions, 'gerarCertificado');

function formatFileName(nomeAluno) {
    // Remove todos os caracteres especiais exceto hífen, underscore e ponto
    return nomeAluno.replace(/[^\w\s-.]/gi, "").replace(/\s+/g, "-");
}

function EmitirCertificado(props) {

    //console.info("PROPS",props);
    const user = props.user;
    //const [user, loading, error] = useAuthState(auth);
    const nomeEntrada = user && user.displayName ? user.displayName : "";

    const [nomeAluno, setNomeAluno] = useState(nomeEntrada);
    const nomeRef = useRef(null);
    const refBtnGerarCertificado = useRef(null);

    const [textoBtnGerar, setTextoBtnGerar] = useState("Gerar Certificado");
    const [disableBtnGerar, setdisableBtnGerar] = useState(false);

    const fetchUserName = async () => {
        try {
          const q = query(collection(db, "users"), where("uid", "==", user?.uid));
          const doc = await getDocs(q);
          const data = doc.docs[0].data();

          setNomeAluno(data.name);
        } catch (err) {
          console.error(err);
          //alert("An error occured while fetching user data");
        }
      };

      useEffect(() => {
        if (user) {
            fetchUserName();
            setNomeAluno(nomeEntrada);
        }
        nomeRef.current.focus();
    }, [nomeEntrada, user]);


    const handleDownload = async () => {

        if (nomeAluno == "") {
            alert('Por favor, informe o seu nome.');
            nomeRef.current.focus();
            return;
        }

        const auth = getAuth();
        const token = await auth.currentUser.getIdToken(/* forceRefresh */ true);

        try {
            setTextoBtnGerar("Processando...");
            setdisableBtnGerar(true);

            const response = await gerarCertificado({nomeAluno}, { auth: token });

            const pdfBytes = new Uint8Array( Object.values(response.data) );

            const pdfBlob = new Blob([pdfBytes], {type: "application/pdf", endings: "transparent"});

            const url = URL.createObjectURL(pdfBlob);
            const link = document.createElement("a");
            const novoNome = formatFileName(nomeAluno);
            link.href = url;
            link.download = `Certificado-${novoNome}.pdf`;
            link.click();
            URL.revokeObjectURL(url);        
        } catch (error) {
            console.error("Erro ao gerar certificado ",error);
            alert('Ocorreu um erro ao gerar o certificado. Por favor tente novamente mais tarde.');
        }
        setTextoBtnGerar("Gerar Certificado");
        setdisableBtnGerar(false);
};

    return ( 
        <div>
            <CabecalhoLogotipo somenteLogotipo="true" />

            <div className="login">
              <div className="login__container modalAcessar">
                <div className="headerLogin modalAcessar">
                    Emitir Certificado de Participação
                </div>

                <label htmlFor="nomeAluno" > Nome do participante </label>
                <input  className="login__textBox" type="text" id = "nomeAluno" ref={nomeRef} required={true} value = {nomeAluno} onChange = {e => setNomeAluno(e.target.value)} />
                <br/>
                <button disabled={disableBtnGerar} ref={refBtnGerarCertificado} className="login__btn" onClick = {handleDownload} > {textoBtnGerar} </button>
                <BotaoDesconectar className="btnDesconectar2"/>
              </div>
            </div>
        </div>
    );
}

export default EmitirCertificado;